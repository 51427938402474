const resume = {
  portfolio: [
    // {
    //   title: 'Texas Tracker',
    //   description: 'Follow us throughout the Texas Legislative session as we track the key issues and latest developments from the Capitol in Austin.',
    //   date: '1/8/2019',
    //   comments: '',
    //   preview: 'tracker',
    //   type: 'web',
    //   paywall: true,
    //   web: true,
    //   link: 'https://interactives.dallasnews.com/2019/texas-tracker/',
    //   tech: ['html', 'scss', 'javascript', 'jQuery'],
    //   shoutouts: ['@adchavez', '@ArianaNGiorgi',],
    //   summary: 'Stylized, interactive front end married to an in-house Django API that allows users to customize their experience.'
    // },
    {
      title: 'The Shifting Tide',
      description: 'Beto O’Rourke’s near loss in the 2018 Senate election shows that while Texas is still red, in some parts of the state, the current may be changing.',
      date: '11/15/2018',
      comments: '',
      preview: 'politics',
      type: 'web',
      web: true,
      link: 'http://www.jlsmith.net/interactives/election/',
      code: 'https://github.com/LayneSmith/dmn_project-code-files/tree/master/elections',
      tech: ['html', 'scss', 'javascript', 'jQuery', 'D3', 'ScrollMagic', 'GSAP'],
      summary: 'In-depth data analysis converted into a user experience that helps tell a very comlicated story.'

    },
    {
      title: 'Texas schools, graded A to F',
      description: '2018 was the first year the Texas Education Agency has issued grades to school districts in an A-F system based on how well they\'re educating kids.',
      date: '9/20/2018',
      comments: '',
      preview: 'grades',
      type: 'web',
      web: true,
      link: 'http://www.jlsmith.net/interactives/schools/',
      code: 'https://github.com/LayneSmith/dmn_project-code-files/tree/master/grades',
      tech: ['html', 'scss', 'javascript', 'jQuery', 'D3', 'MapboxGL', 'SQL'],
      summary: 'Big data analysis and visualization customized to each user\'s input.'

    },
    {
      title: 'Standoff',
      description: 'This is the first full account of what happened inside El Centro College in the darkness and chaos of July 7, 2016.',
      date: '2/2/2018',
      comments: '',
      preview: 'standoff',
      type: 'web',
      web: true,
      link: 'http://www.jlsmith.net/interactives/standoff/',
      tech: ['html', 'scss', 'javascript', 'jQuery', 'ScrollMagic', 'GSAP'],
      shoutouts: ['@Hancock_JohnD', '@MichaelHogueDMN', '@SmileyPool'],
      summary: 'A complex narrative incorporating riveting content, strong design and animations to take usersa on a journey through a tragic event.'

    },
    {
      title: 'Visualizing Adrian Beltre’s march to 3,000 hits',
      description: 'Check out a complete visualization of Adrian Beltre\'s march to 3,000 hits.',
      date: '7/30/2017',
      comments: '',
      preview: 'beltre',
      type: 'web',
      web: true,
      link: 'http://www.jlsmith.net/interactives/beltre/',
      tech: ['html', 'scss', 'javascript', 'jQuery', 'D3', 'Python'],
      summary: 'Design and data visualization that combines low-level data with high-level ideas to tell a story.'

    },
    {
      title: 'How have the Dallas Mavericks performed in the NBA draft?',
      description: 'Let\'s face it, the Dallas Mavericks don\'t have a good track record when it comes to the draft.',
      date: '6/21/2017',
      comments: '',
      preview: 'mavs',
      type: 'web',
      web: true,
      link: 'http://www.jlsmith.net/interactives/mavs-draft/',
      tech: ['html', 'scss', 'javascript', 'jQuery', 'D3', 'Python'],
      summary: 'Design and data visualization that combines data points with visualization tools and allows users to engage with the datapoints they care about.'

    },
    {
      title: 'How have the Dallas Cowboys performed in the NFL draft?',
      description: 'Over the years, Dallas\' picks have been a mixed bag. Here\'s our assessment of every one.',
      date: '4/24/2017',
      comments: '',
      preview: 'cowboys',
      type: 'web',
      web: true,
      link: 'http://www.jlsmith.net/interactives/cowboys-draft/',
      tech: ['html', 'scss', 'javascript', 'jQuery', 'D3', 'Python'],
      summary: 'Data visualization that allows users to clearly see the relationship between hundreds of individual datapoints.'

    },
    // {
    //   title: 'Behind the numbers: Dak\'s rookie season',
    //   description: 'Statistics tell the story, one of the best the NFL has seen in a long, long time.',
    //   date: '2/3/2017',
    //   comments: '',
    //   preview: 'dak',
    //   type: 'web',
    //   web: true,
    //   link: 'http://www.jlsmith.net/interactives/qbs/',
    //   tech: ['html', 'scss', 'javascript', 'jQuery', 'D3', 'Python'],
    //   summary: 'Numbers oriented data visualization helping users to formulate a conclusion.'

    // },
    // {
    //   title: 'The battle for America’s guns',
    //   description: 'Lobbyists spend millions of dollars every year trying to influence gun policy in America.',
    //   date: '11/18/2016',
    //   comments: '',
    //   preview: 'guns',
    //   type: 'web',
    //   web: true,
    //   link: 'http://www.jlsmith.net/interactives/guns/',
    //   tech: ['html', 'scss', 'javascript', 'jQuery', 'D3', 'Python', 'API'],
    //   summary: 'A user experience that, using data visualization and design, helps to illustrate a controversial issue.'
    // },
    // {
    //   title: 'Dallas Cowboys Operation Game',
    //   description: 'The classic board game “Operation” requires steady hands. Cowboys team doctors need those, too.',
    //   date: '9/8/2016',
    //   comments: '',
    //   preview: 'operation',
    //   type: 'web',
    //   web: true,
    //   link: 'http://www.jlsmith.net/interactives/operation/',
    //   summary: 'A fun interactive game because, who doesn\'t love Operation?!'
    // },
    {
      title: 'NASCAR Haulers',
      description: 'When NASCAR comes to town, there\'s more to pack than just suitcases.',
      date: '2005',
      type: 'print',
      print: true,
      preview: 'haulers.jpg',
    },
    // {
    //   title: 'Inspired Vision',
    //   description: 'Dallas cathedral’s bell tower will complete architect’s dream.',
    //   date: '2/26/2005',
    //   type: 'print',
    //   print: true,
    //   preview: 'cathedral.jpg',
    // },
    {
      title: 'Surgeon’s Big Challenge',
      description: 'Doctors believe extensive study and planning, plus special equipment, will improve odds of success.',
      date: '2004',
      type: 'print',
      print: true,
      preview: 'twins.jpg',
    },
    {
      title: 'Urban Beauty',
      description: 'An early look at the Nasher Sculpture Garden in Dallas.',
      date: '2003',
      type: 'print',
      print: true,
      preview: 'nasher.jpg',
    },
    // {
    //   title: 'The Gulf Between the Wars',
    //   description: 'It’s been 12 years since Desert Storm. While the names and places may be the same, the difference can be found on the weapons-technology front.',
    //   date: '2003',
    //   type: 'print',
    //   print: true,
    //   preview: 'gulf.jpg',
    // },
  ],
  experience: [
    {
      title: 'Interactives Editor',
      time: 'April 2016 – January 2019',
      description: 'Designed and developed responsive interactive presentations, meeting goals to engage with users and contribute to company conversion rates, overall traffic and time spent on site. Each presentation process included a creative brainstorming role, immediately followed by a technical developmental role. Creative roles included sketching, wireframing, prototyping and feedback while developmental roles included implementation using HTML, CSS, JavaScript and D3. Contributed to monthly critiquing sessions and departmental hack-a-thons in which unique, innovative solutions were created to provide value to internal and external users.',
      skills: 'SCSS, D3, ES6, Python, GitHub',
    },
    {
      title: 'Graphics and Multimedia Director',
      time: '2003 – 2016',
      description: 'Led, developed and worked in the graphics and multimedia team to create visual content and data-driven journalism across multiple platforms including print, mobile and desktop. The team\'s primary role was to collaborate with different product owners, creating visual content such as interactive data visualizations, graphics and animations.',
      skills: 'Project management, public speaking, teaching, jQuery, Javascript',
    },
    {
      title: 'Multimedia Editor',
      time: '2002 – 2003',
      description: 'Worked closely with business partners to create online content covering multiple subjects, often on tight deadlines. Projects followed a set of best practices, styles and operational guidelines meant to engage users while reducing production times.',
      skills: 'Adobe Flash, HTML, CSS, user experience, user interface',
    },
    // {
    //   title: 'Senior Artist',
    //   time: '1998 – 2002',
    //   description: 'Worked closely with multiple departments to research and report a wide range of visuals used to support and accompany news and editorial coverage. Produced original graphic content including data  visualizations, illustrations and animations, often under tight deadlines.',
    //   skills: 'Adobe Photoshop, Adobe Illustrator, 3D design and illustration, visual design and communication, data visualization',
    // },
  ],
  awards: [
    {
      title: 'Award of Excellence',
      time: '2017',
      description: 'Graphics: Planned Coverage',
      winner: 'Beltre\'s March to 3,000 Hits',
      issued: 'Society of News Design',
      logo: 'snd',
      image: 'beltre',
      link: 'https://interactives.dallasnews.com/2017/beltre-3000-hits/',
    },
    {
      title: 'Award of Excellence',
      time: '2015',
      description: 'Features',
      winner: 'Lucha Libre in Dallas',
      issued: 'Society of News Design',
      logo: 'snd',
      image: 'luchalibre',
      link: 'http://interactives.dallasnews.com/2015/lucha-libre/',
    },
    {
      title: 'Award of Excellence',
      time: '2014',
      description: 'Combination Print and Digital News',
      winner: 'Dallas Cowboys Legends Quiz',
      issued: 'Society of News Design',
      logo: 'snd',
      image: 'cowboys',
      link: 'http://res.dallasnews.com/graphics/2014_09/cowboysfaces/',
    },
    {
      title: 'Award of Excellence',
      time: '2014',
      description: 'Features',
      winner: 'Final Fork',
      issued: 'Society of News Design',
      logo: 'snd',
      image: 'finalfork',
      link: 'http://res.dallasnews.com/graphics/2014_03/finalfork/',
    },
    {
      title: 'Award of Excellence',
      time: '2012',
      description: 'Features, Use of Multimedia',
      winner: 'Lost Dallas (iPad exclusive)',
      issued: 'Society of News Design',
      logo: 'snd',
      image: 'lostdallas',
      link: 'http://res.dallasnews.com/graphics/2012_07/lostdallas/',
    },
    {
      title: 'Award of Excellence',
      time: '2005',
      description: 'Information Graphics',
      winner: 'Twin Destinies',
      issued: 'Society of News Design',
      logo: 'snd',
      image: 'twins-thumb',
    },
    {
      title: 'Katie Award',
      time: '2004',
      description: 'Visual Communication Infographic',
      winner: 'Conjoined Twins',
      issued: 'Press Club of Dallas',
      logo: 'katie',
      image: 'twins',
    },
    {
      title: 'Katie Award',
      time: '2002',
      description: 'Web Site News Graphics',
      winner: 'Fake Drugs, Real Lives',
      issued: 'Press Club of Dallas',
      logo: 'katie',
      image: 'fakedrugs',
    },
    {
      title: 'Katie Award',
      time: '2001',
      description: 'Visual Communication Infographic',
      winner: 'Hit to Kill',
      issued: 'Press Club of Dallas',
      logo: 'katie',
      image: 'hittokill',
    },
    {
      title: 'Katie Award',
      time: '2001',
      description: 'Web Site News Graphics',
      winner: 'Galveston’s 1900 Hurricane',
      issued: 'Press Club of Dallas',
      logo: 'katie',
      image: 'galveston',
    },
    // {
    //   title: 'Katie Award',
    //   time: '1999',
    //   description: 'Visual Communication Infographic',
    //   winner: 'Roaring Rapids Accident',
    //   issued: 'Press Club of Dallas',
    //   logo: 'katie',
    // },
    {
      title: 'First Place',
      time: '2003',
      description: 'Infographics - Class AAAA',
      winner: '',
      issued: 'Texas Associated Press Managing Editors',
      logo: 'tapme',
    },
    {
      title: 'First Place',
      time: '2000',
      description: 'Infographics - Class AAAA',
      winner: '',
      issued: 'Texas Associated Press Managing Editors',
      logo: 'tapme',
    },
  ],
};

export default resume;
